import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LinkHandler from "../../utils/link-handler";

const LightOrangeTitleWithContentAndImage = ({ data }) => {
  const sectionImg = getImage(data.image.localFile);
  const columnsClasses = "g-col-2 g-col-xl-1";
  const sectionId = convertId(data.name);
  return (
    <section
      id={sectionId}
      className="light-orange-title-with-content-and-image"
    >
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="lotwcai-title bg-primary">
        <div className="container">
          <div className="grid grid-lotwcai">
            <div
              className={`lotwcai-title-holder h2 h-inherit text-white h-reset ${columnsClasses}`}
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div className={`d-none d-xl-block ${columnsClasses}`} />
          </div>
        </div>
      </div>
      <div className="lotwcai-main">
        <div className="container">
          <div className="grid grid-lotwcai">
            <div className={`lotwcai-content-holder ${columnsClasses}`}>
              <div
                className="content lotwcai-content"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
              {data.button && (
                <LinkHandler
                  className="btn btn-primary mt-30"
                  href={data.button.url}
                >
                  {data.button.title}
                </LinkHandler>
              )}
            </div>
            <div className={`lotwcai-image ${columnsClasses}`}>
              {data.image.localFile.childImageSharp ? (
                <GatsbyImage alt={data.image.altText} image={sectionImg} />
              ) : (
                <img
                  className="img-fluid d-block"
                  alt={data.image.altText}
                  src={data.image.localFile.url}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LightOrangeTitleWithContentAndImage;

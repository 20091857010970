import React from "react";
import convertId from "../../utils/convert-id";
import { useStaticQuery, graphql, Link } from "gatsby";
import LinkHandler from "../../utils/link-handler";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const RecentProjects = ({ data }) => {
  const {
    allWpPage: { nodes: projects },
  } = useStaticQuery(
    graphql`
      query RecentProjects {
        allWpPage(
          limit: 12
          filter: { page: { type: { eq: "project" } } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            id
            page {
              projectName
              projectLocation
              projectThumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 585, quality: 100)
                  }
                }
                altText
              }
            }
            uri
          }
        }
      }
    `
  );

  const sectionId = convertId(data.name);

  return (
    <section id={sectionId} className="recent-projects">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div
          className="rp-title text-left text-md-center h2 h-inherit h-reset mb-55 mb-md-65"
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
        <div className="rp-projects grid d-block d-xl-grid">
          {projects.slice(0, data.limitToShow).map((project) => {
            const projectImage = getImage(
              project.page.projectThumbnail.localFile
            );
            return (
              <div className="rp-project g-col-12 g-col-xl-4" key={project.id}>
                <Link className="rp-project-link" to={project.uri}>
                  <div className="rp-project-thumb">
                    <GatsbyImage
                      image={projectImage}
                      alt={project.page.projectThumbnail.altText}
                    />
                  </div>
                  <div className="rp-project-content bg-primary text-white">
                    <div
                      className="rp-project-name h5 h-inherit h-reset mb-20"
                      dangerouslySetInnerHTML={{
                        __html: project.page.projectName,
                      }}
                    />
                    <div
                      className="h4 h-inherit h-reset"
                      dangerouslySetInnerHTML={{
                        __html: project.page.projectLocation,
                      }}
                    />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="rp-btn d-flex justify-content-center mt-40 mt-md-75">
          <LinkHandler className="btn btn-dark" href={data.button.url}>
            {data.button.title}
          </LinkHandler>
        </div>
      </div>
    </section>
  );
};

export default RecentProjects;

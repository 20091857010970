import React from "react";
import convertId from "../../utils/convert-id";

const Title = ({ data }) => {
  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="title bg-graph-light">
      <div id={`${sectionId}-top`} className="to-section" />
      <div
        className="title-content text-white h1 h-reset h-inherit"
        dangerouslySetInnerHTML={{ __html: data.title }}
      />
    </section>
  );
};

export default Title;

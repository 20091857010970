import React from "react";
import convertId from "../../utils/convert-id";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LinkHandler from "../../utils/link-handler";

const AllProjects = ({ data }) => {
  const {
    allWpPage: { nodes: projects },
  } = useStaticQuery(
    graphql`
      query AllProjects {
        allWpPage(
          filter: { page: { type: { eq: "project" } } }
          sort: { fields: menuOrder, order: ASC }
        ) {
          nodes {
            menuOrder
            id
            page {
              projectTitle
              projectThumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 650, height: 644, quality: 100)
                  }
                }
                altText
              }
            }
            uri
          }
        }
      }
    `
  );

  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="all-projects bg-graph-light">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div className="grid grid-ap">
          {projects.map((project) => {
            const projectImage = getImage(
              project.page.projectThumbnail.localFile
            );
            return (
              <div className="ap-project g-col-12 g-col-xl-6" key={project.id}>
                <LinkHandler className="ap-project-link" href={project.uri}>
                  <div className="ap-project-thumb">
                    <GatsbyImage
                      image={projectImage}
                      alt={project.page.projectThumbnail.altText}
                    />
                  </div>
                  <div className="ap-project-content bg-primary text-white">
                    <div
                      className="ap-project-title h5 h-inherit h-reset text-center"
                      dangerouslySetInnerHTML={{
                        __html: project.page.projectTitle,
                      }}
                    />
                  </div>
                </LinkHandler>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AllProjects;

import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const LightContentWithTwoCaptionedImages = ({ data }) => {
  const sectionImg = getImage(data.image.localFile);
  const sectionId = convertId(data.name);
  return (
    <section
      id={sectionId}
      className="light-content-with-two-captioned-images bg-graph-light"
    >
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div className="grid grid-lcwtci">
          <div className="lcwtci-main g-col-2 g-col-xl-1">
            <div
              className="lcwtci-title h2 h-reset h-inherit mb-20 mb-md-30"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
              className="lcwtci-content content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
          <div className="lcwtci-img lcwtci-img-1 g-col-2 g-col-sm-1">
            <figure className="figure figure-primary figure-slim w-100">
              <GatsbyImage
                className="figure-img img-fluid"
                alt={data.image.altText}
                image={sectionImg}
              />
              <figcaption
                className="figure-caption h3 h-inherit h-reset text-white text-center bg-primary"
                dangerouslySetInnerHTML={{ __html: data.imageCaption }}
              />
            </figure>
          </div>
          <div className="lcwtci-img lcwtci-img-2 g-col-2 g-col-sm-1">
            <figure className="figure figure-primary figure-slim w-100">
              <GatsbyImage
                className="figure-img img-fluid"
                alt={data.image.altText}
                image={sectionImg}
              />
              <figcaption
                className="figure-caption h3 h-inherit h-reset text-white text-center bg-primary"
                dangerouslySetInnerHTML={{ __html: data.imageCaption }}
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LightContentWithTwoCaptionedImages;

import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const DarkContentWithFullLeftImage = ({ data }) => {
  const sectionImg = getImage(data.image.localFile);
  const columnClasses = "g-col-2 g-col-xl-1";
  const sectionId = convertId(data.name);
  return (
    <section
      id={sectionId}
      className="dark-content-with-full-left-image bg-dark bg-graph text-white position-relative"
    >
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div className="grid grid-dcwfli">
          <div className={`dcwfli-img-holder ${columnClasses}`}>
            <div className="dcwfli-img">
              <GatsbyImage alt={data.image.altText} image={sectionImg} />
            </div>
          </div>
          <div
            className={`dcwfli-content-holder d-flex flex-column justify-content-center ${columnClasses}`}
          >
            <div
              className="dcwfli-title h2 h-reset h-inherit mb-20 mb-md-40"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
              className="content dcwfli-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DarkContentWithFullLeftImage;

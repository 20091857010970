import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import convertId from "../../utils/convert-id";

const youtubeParser = (url) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

const BannerImage = ({ data }) => {
  const bannerBg = getImage(data.bannerBackground.localFile);
  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="banner-image bg-primary">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="banner-image-holder">
        {!data.bannerVideo && !data.youtubeVideoUrl ? (
          <GatsbyImage
            className="d-block"
            image={bannerBg}
            alt={data.bannerBackground.altText}
            loading="eager"
          />
        ) : data.bannerVideo ? (
          <video
            className="banner-image-video"
            playsInline
            autoPlay
            muted
            loop
            poster={data.bannerBackground.mediaItemUrl}
          >
            <source src={data.bannerVideo.mediaItemUrl} type="video/mp4" />
          </video>
        ) : (
          <iframe
            className="banner-image-video-yt"
            title="Video Background"
            src={`https://www.youtube-nocookie.com/embed/${youtubeParser(
              data.youtubeVideoUrl
            )}?controls=0&autoplay=1&mute=1&loop=1&showinfo=0&autohide=1&modestbranding=1&rel=0&fs=0&playlist=${youtubeParser(
              data.youtubeVideoUrl
            )}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </section>
  );
};

export default BannerImage;

import React from "react";
import { Helmet } from "react-helmet";
import sections from "../../utils/sections";

const Builder = ({ title, page, themeSettings }) => {
  return (
    <>
      <Helmet>
        <title>
          {page.metaTitle ? page.metaTitle : title} -{" "}
          {themeSettings.siteMetaTitle}
        </title>
        {page.metaDescription && (
          <meta name="description" content={page.metaDescription} />
        )}
        {page.customCssOverrides && <style>{page.customCssOverrides}</style>}
      </Helmet>
      {page.pageBuilder &&
        page.pageBuilder.map((section) => {
          const sectionName = section.fieldGroupName.replace(
              "Page_Page_PageBuilder_",
              ""
            ),
            Section = sections[sectionName];
          return <Section data={section} key={section.name} />;
        })}
    </>
  );
};

export default Builder;

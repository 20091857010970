import React, { useEffect } from "react";
import convertId from "../../utils/convert-id";
import LinkHandler from "../../utils/link-handler";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "glightbox/dist/css/glightbox.min.css";
//import { GLightbox } from "glightbox";

const Project = ({ data }) => {
  useEffect(() => {
    let lightbox;

    /* You have to comment this out and import like a normal import during dev. In production, this library is imported in html.js.
      There must be a better way to handle this, but this will work for now. */
    lightbox = GLightbox();

    return () => {
      lightbox = undefined;
    };
  });

  const sectionId = convertId(data.name),
    gridChildrenBp = "xxl",
    gridChildrenClasses = `g-col-2 g-col-${gridChildrenBp}-1`;
  return (
    <section id={sectionId} className="project">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="project-title bg-primary spacing-x">
        <div className="container">
          <div className="project-title-holder grid grid-pp">
            <div
              className={`project-title-content text-white h2 h-inherit h-reset text-shadow-primary pt-55 pb-25 ${gridChildrenClasses}`}
              dangerouslySetInnerHTML={{ __html: data.projectTitle }}
            />
            <div
              className={`${gridChildrenClasses} d-none d-${gridChildrenBp}-block`}
            />
          </div>
        </div>
      </div>
      <div className="project-projects bg-graph-light spacing-x">
        <div className="container">
          <div className="grid grid-pp">
            <div
              className={`pp-content pt-50 spacing-bottom ${gridChildrenClasses}`}
            >
              <div
                className="content content-pp"
                dangerouslySetInnerHTML={{ __html: data.projectContent }}
              />
              {data.button && (
                <LinkHandler
                  className="btn btn-dark mt-40"
                  href={data.button.url}
                >
                  {data.button.title}
                </LinkHandler>
              )}
            </div>
            <div className={`grid-pp-imgs grid pb-60 ${gridChildrenClasses}`}>
              {data.gallery.map((img) => {
                const galImg = getImage(img.localFile);
                return (
                  <a
                    href={img.localFile.publicURL}
                    className="d-block pp-img g-col-12 g-col-sm-6 g-col-lg-4 glightbox"
                    key={img.id}
                    data-gallery={"gallery-" + sectionId}
                  >
                    <GatsbyImage alt={img.altText} image={galImg} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import convertId from "../../utils/convert-id";

const BillboardSlider = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="billboard-slider carousel slide carousel-fade"
      data-bs-ride="carousel"
      data-bs-pause="false"
    >
      <div className="carousel-inner">
        {data.slides.map((slide, index) => {
          const slideBg = getImage(slide.background.localFile);
          return (
            <div
              className={"carousel-item" + (index === 0 ? " active" : "")}
              key={slide.name}
              id={convertId(slide.name)}
            >
              <GatsbyImage
                className="d-block w-100 h-100"
                image={slideBg}
                alt={slide.background.altText}
                loading="eager"
              />
              <div
                className="carousel-caption"
                dangerouslySetInnerHTML={{ __html: slide.content }}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BillboardSlider;

import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LinkHandler from "../../utils/link-handler";

const ContentWith3FeaturedBoxes = ({ data }) => {
  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="content-with-3-featured-boxes bg-white">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="cw3fb-title">
        <div className="container">
          <div
            className="cw3fb-title-holder h2 h-inherit text-white h-reset"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        </div>
      </div>
      <div className="cw3fb-main">
        <div className="container">
          <div className="grid grid-cw3fb">
            <div className="cw3fb-content-holder g-col-2 g-col-xl-1">
              <div
                className="content cw3fb-content"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
              {data.button && (
                <LinkHandler
                  className="btn btn-dark mt-30 mt-xl-60"
                  href={data.button.url}
                >
                  {data.button.title}
                </LinkHandler>
              )}
            </div>
            <div className="cw3fb-boxes grid g-col-2 g-col-xl-1">
              {data.boxes.map((box) => {
                const boxImg = getImage(box.image.localFile);
                return (
                  <div
                    className="cw3fb-box-holder"
                    id={convertId(box.name)}
                    key={box.name}
                  >
                    <LinkHandler className="cw3fb-box" href={box.link.url}>
                      <GatsbyImage alt={box.image.altText} image={boxImg} />
                      <div
                        className="cw3fb-box-title h3 h-inherit h-reset"
                        dangerouslySetInnerHTML={{ __html: box.title }}
                      />
                    </LinkHandler>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentWith3FeaturedBoxes;

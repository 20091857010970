import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PageIntro = ({ data }) => {
  const sectionImg = getImage(data.image.localFile),
    gridClasses = "g-col-12 g-col-xl-6";
  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="page-intro bg-graph-light spacing-x">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="pi-title">
        <div
          className="title-content text-white h-reset h-inherit h1"
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
      </div>
      <div className="container">
        <div className="grid grid-pi">
          <div className={`pi-img-holder ${gridClasses}`}>
            <div className="pi-img">
              <GatsbyImage alt={data.image.altText} image={sectionImg} />
            </div>
          </div>
          <div
            className={`pi-content-holder d-flex flex-column justify-content-center ${gridClasses}`}
          >
            <div
              className="content pi-content content-spacing"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageIntro;

import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const DarkContentWithCaptionedImage = ({ data }) => {
  const sectionImg = getImage(data.image.localFile);
  const columnClasses = "g-col-2 g-col-xl-1";
  const sectionId = convertId(data.name);
  return (
    <section
      id={sectionId}
      className="dark-content-with-captioned-image bg-dark text-white"
    >
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div className="grid grid-dcwci">
          <div className={`dcwci-main ${columnClasses}`}>
            <div
              className="dcwci-title h2 h-reset h-inherit mb-20 mb-md-45"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
              className="dcwci-content content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
          <div className={`dcwci-img ${columnClasses}`}>
            <figure className="figure figure-primary w-100">
              <GatsbyImage
                className="figure-img"
                alt={data.image.altText}
                image={sectionImg}
              />
              <figcaption
                className="figure-caption h5 h-inherit h-reset text-white text-center bg-primary"
                dangerouslySetInnerHTML={{ __html: data.imageCaption }}
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DarkContentWithCaptionedImage;

import React from "react";
import convertId from "../../utils/convert-id";
import LinkHandler from "../../utils/link-handler";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ContentWithImage = ({ data }) => {
  const sectionImg = getImage(data.image.localFile);
  const sectionId = convertId(data.name);
  return (
    <section
      id={sectionId}
      className="content-with-image bg-dark text-white position-relative bg-graph"
    >
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div className="grid grid-cwi">
          <div className="cwi-content-holder g-col-2 g-col-xl-1">
            <div
              className="cwi-titles"
              dangerouslySetInnerHTML={{ __html: data.titles }}
            />
            <div
              className="content cwi-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
            {data.button && (
              <LinkHandler
                className="btn btn-primary mt-45"
                href={data.button.url}
              >
                {data.button.title}
              </LinkHandler>
            )}
          </div>
          <div className="cwi-img-holder g-col-2 g-col-xl-1">
            <div className="cwi-img">
              <GatsbyImage alt={data.image.altText} image={sectionImg} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentWithImage;

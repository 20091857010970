import React from "react";
import convertId from "../../utils/convert-id";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const LightContentWithRightImage = ({ data }) => {
  const sectionImg = getImage(data.image.localFile);
  const columnClasses = "g-col-2 g-col-xl-1";
  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="light-content-with-right-image">
      <div id={`${sectionId}-top`} className="to-section" />
      <div className="container">
        <div className="grid grid-lcwri">
          <div
            className={`lcwri-main d-flex flex-column justify-content-center ${columnClasses}`}
          >
            <div
              className="lcwri-title h2 h-inherit h-reset mb-20 mb-md-35"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
              className="content lcwri-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
          <div className={`lcwri-image ${columnClasses}`}>
            <GatsbyImage
              className="figure-img img-fluid"
              alt={data.image.altText}
              image={sectionImg}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LightContentWithRightImage;

import React from "react";
import convertId from "../../utils/convert-id";

const BannerMessage = ({ data }) => {
  const sectionId = convertId(data.name);
  return (
    <section id={sectionId} className="banner-message bg-primary">
      <div
        className="banner-message-content content text-center text-white py-10"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    </section>
  );
};

export default BannerMessage;

import BillboardSlider from "../components/sections/billboard-slider";
import ContentWith3FeaturedBoxes from "../components/sections/content-with-3-featured-boxes";
import ContentWithImage from "../components/sections/content-with-image";
import BannerImage from "../components/sections/banner-image";
import RecentProjects from "../components/sections/recent-projects";
import Project from "../components/sections/project";
import Title from "../components/sections/title";
import AllProjects from "../components/sections/all-projects";
import PageIntro from "../components/sections/page-intro";
import DarkContentWithCaptionedImage from "../components/sections/dark-content-with-captioned-image";
import LightContentWithTwoCaptionedImages from "../components/sections/light-content-with-two-captioned-images";
import LightOrangeTitleWithContentAndImage from "../components/sections/light-orange-title-with-content-and-image";
import DarkContentWithFullLeftImage from "../components/sections/dark-content-with-full-left-image";
import LightContentWithRightImage from "../components/sections/light-content-with-right-image";
import CareersForm from "../components/sections/careers-form";
import BannerMessage from "../components/sections/banner-message";

const sections = {
  BillboardSlider: BillboardSlider,
  ContentWith3FeaturedBoxes: ContentWith3FeaturedBoxes,
  ContentWithImage: ContentWithImage,
  BannerImage: BannerImage,
  RecentProjects: RecentProjects,
  Project: Project,
  Title: Title,
  AllProjects: AllProjects,
  PageIntro: PageIntro,
  DarkContentWithCaptionedImage: DarkContentWithCaptionedImage,
  LightContentWithTwoCaptionedImages: LightContentWithTwoCaptionedImages,
  LightOrangeTitleWithContentAndImage: LightOrangeTitleWithContentAndImage,
  DarkContentWithFullLeftImage: DarkContentWithFullLeftImage,
  LightContentWithRightImage: LightContentWithRightImage,
  CareersForm: CareersForm,
  BannerMessage: BannerMessage,
};

export default sections;

import React from "react";
import { graphql } from "gatsby";
import Builder from "../components/global/builder";

const Page = ({
  data: {
    wpPage: { title, page },
    wp: {
      acfOptionsThemeSettings: { themeSettings },
    },
  },
}) => {
  return <Builder title={title} page={page} themeSettings={themeSettings} />;
};

export const pageQuery = graphql`
  query Page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        customCssOverrides
        pageBuilder {
          ... on WpPage_Page_PageBuilder_BillboardSlider {
            name
            fieldGroupName
            slides {
              name
              background {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                altText
              }
              content
            }
          }
          ... on WpPage_Page_PageBuilder_ContentWith3FeaturedBoxes {
            name
            fieldGroupName
            title
            content
            boxes {
              name
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                altText
              }
              title
              link {
                url
              }
            }
            button {
              url
              title
            }
          }
          ... on WpPage_Page_PageBuilder_ContentWithImage {
            name
            fieldGroupName
            titles
            content
            button {
              title
              url
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
          }
          ... on WpPage_Page_PageBuilder_BannerImage {
            name
            fieldGroupName
            bannerBackground {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              mediaItemUrl
            }
            bannerVideo {
              mediaItemUrl
            }
            youtubeVideoUrl
          }
          ... on WpPage_Page_PageBuilder_RecentProjects {
            name
            fieldGroupName
            title
            button {
              url
              title
            }
            limitToShow
          }
          ... on WpPage_Page_PageBuilder_Project {
            name
            fieldGroupName
            projectTitle
            projectContent
            button {
              url
              title
            }
            gallery {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                publicURL
              }
              altText
            }
          }
          ... on WpPage_Page_PageBuilder_Title {
            name
            fieldGroupName
            title
          }
          ... on WpPage_Page_PageBuilder_AllProjects {
            name
            fieldGroupName
          }
          ... on WpPage_Page_PageBuilder_PageIntro {
            name
            fieldGroupName
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
            content
          }
          ... on WpPage_Page_PageBuilder_DarkContentWithCaptionedImage {
            name
            fieldGroupName
            title
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
            imageCaption
          }
          ... on WpPage_Page_PageBuilder_LightContentWithTwoCaptionedImages {
            name
            fieldGroupName
            title
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 482)
                }
              }
              altText
            }
            imageCaption
            secondImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 482)
                }
              }
              altText
            }
            secondImageCaption
          }
          ... on WpPage_Page_PageBuilder_LightOrangeTitleWithContentAndImage {
            name
            fieldGroupName
            title
            content
            button {
              title
              url
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                url
              }
              altText
            }
          }
          ... on WpPage_Page_PageBuilder_DarkContentWithFullLeftImage {
            name
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
            title
            content
          }
          ... on WpPage_Page_PageBuilder_LightContentWithRightImage {
            name
            fieldGroupName
            title
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
          }
          ... on WpPage_Page_PageBuilder_CareersForm {
            name
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              altText
            }
            title
            content
          }
          ... on WpPage_Page_PageBuilder_BannerMessage {
            name
            fieldGroupName
            content
          }
        }
      }
    }
    wp {
      acfOptionsThemeSettings {
        themeSettings {
          siteMetaTitle
        }
      }
    }
  }
`;

export default Page;
